import React,{useState,useEffect,useContext,createContext} from 'react'
import {Navigate,useNavigate,Link} from 'react-router-dom'
import '../../css/style.css'
import home from '../../css/images/home.png'
import burn from '../../css/images/burn.png'
import cube3 from '../../css/images/cube3.png'
import chat from '../../css/images/chat.png'
import bell from '../../css/images/bell.png'
import menu from '../../css/images/menu.png'
import {UserContext} from '../../context/UserContext'

export default function Footer3() {
  const {socket,setSocket}=useContext(UserContext)
  const {messageNotifications,setMessageNotifications}=useContext(UserContext)
  const {generalNotifications,setGeneralNotifications}=useContext(UserContext)

  
  return (
    <footer>
    <div className="footer-wrap">
      <div className="footer-div">
        <div className="footer-item">
          <a href="/#/home"
            ><button style={{backgroundImage:'url('+home+')'}}></button>
          </a>
          <div className="footer-item-title">Home</div>
        </div>
        <div className="footer-item">
          <a href="/#/walloffame"><button style={{backgroundImage:'url('+burn+')'}}></button></a>
          <div className="footer-item-title">Wall of Fame</div>
        </div>
        <div className="footer-item">
          <a href="/#/challenges"
            ><button
            style={{backgroundImage:'url('+cube3+')'}}
            ></button
          ></a>
          <div className="footer-item-title">Challenges</div>
        </div>
        <div className="footer-item">
          <a href="/#/chat"
            ><button style={{backgroundImage:'url('+chat+')'}}></button
          >
          {messageNotifications>0 &&
          <div className="footer-bubble">{messageNotifications}</div>
          }
          </a>
          <div className="footer-item-title">Chat</div>
        </div>
        <div className="footer-item">
          <a href="/#/notifications"
            ><button style={{backgroundImage:'url('+bell+')'}}></button
          >
          {generalNotifications>0 &&
          <div className="footer-bubble">{generalNotifications}</div>
          }  
          
          </a>
          <div className="footer-item-title">Notifications</div>
        </div>
        <div className="footer-item">
          <a href="/#/menu"
            ><button style={{backgroundImage:'url('+menu+')'}}></button
          ></a>
          <div className="footer-item-title">Menu</div>
        </div>
      </div>
    </div>
  </footer>
  )
}

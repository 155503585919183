import React,{useRef,useState,useEffect,useContext} from 'react'
import { useParams,Navigate,useNavigate } from 'react-router-dom'
import burnBg from '../../css/images/burn-bg.png'
import burnBlackOrange from '../../css/images/burn-black-orange.png'
import man2 from '../../css/images/man2.jpg'
import man from '../../css/images/man.jpg'
import man3 from '../../css/images/man3.jpg'
import burnWhite from '../../css/images/burn-white.png'
import flame from '../../css/images/flame.png'
import woman from '../../css/images/woman.jpg'
import {UserContext} from '../../context/UserContext'



import Footer from '../Components/Footer'
import axios from 'axios'
import { lang } from 'moment'

export default function WallOfFame() {
  const {isAuth,setIsAuth}=useContext(UserContext)
  const {apiToken,setApiToken} = useContext(UserContext)
  const {userID,setUserID }= useContext(UserContext)
  const {usrPhoto,setUsrPhoto}= useContext(UserContext)
  const {firstName,lastName}= useContext(UserContext)
  const [isLoading,setIsLoading]=useState(true)
  const {sportID,langID}= useContext(UserContext)
  const [sports,setSports]=useState([])
  const [countries,setCountries]=useState([])
  const [positions,setPositions]=useState([])
  const [wallOfFame,setWallOfFame]=useState([])
  const [profileSaved,setProfileSaved]=useState(0)
  const [usersWhoBlockedMe,setUsersWhoBlockedMe]=useState([])
  const { loadUnreadNotifications}=useContext(UserContext)
  const [allResults,setAllResults]=useState([])
  const [age,setAge]=useState(0)
  const [myScore,setMyScore]=useState(0)
  const navigate=useNavigate()


  useEffect(()=>{
    loadData()
  },[])

  const loadData=()=>{
    setIsLoading(true)
    axios.get(process.env.REACT_APP_API_SERVER+'walloffame/'+sportID+'/'+userID+'/'+langID,{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("token")
          }            
      })
    .then(res=>{

      const curYear=new Date().getFullYear()
        //console.log(curYear)
        res.data.results.forEach(item=>{
          item.age=curYear-item.DateOfBirth.split('-')[0]
        })
       //setMyScore(res.data.results.filter(item=>item.UUID==userID)[0].v)
       setWallOfFame(res.data.results.filter(item=>item.v>0).slice(0, 19))
       setAllResults(res.data.results.filter(item=>item.v>0))
       setMyScore(res.data.results.filter(item=>item.UUID==userID)[0].v)

       loadUnreadNotifications(userID)

       setIsLoading(false)
    }).catch(err=>{
        setIsLoading(false)

    }) 
  }  

  const loadUsersWhoBlockedMe=()=>{
    setIsLoading(true)
    axios.get(process.env.REACT_APP_API_SERVER+'block/blockeduser/'+userID,{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("token")
          }            
      })
    .then(res=>{

      setUsersWhoBlockedMe(res.data.friends)
      //console.log(res.data.friends)
      setIsLoading(false)
    }).catch(err=>{
        setIsLoading(false)
        //console.log(err)
    }) 
  }

  const loadImage=(id)=>{
    const d=Date.now()
    //alert(`${process.env.REACT_APP_API_SERVER}files/${userID}/${d}`)
    return(`${process.env.REACT_APP_API_SERVER}files/${id}/${d}`)
  }

  const wallOfFameList=wallOfFame.map((item,index)=>{

   // if (usersWhoBlockedMe.filter(u=>u.UserWhoBlockedMeUUID==item.UUID).length==0){
    return(
      <div className="wof-avatar-item bg-orange" key={index}>
      <div className="wof-wrapper">
        <div className="wof-user">
          <div onClick={()=>navigate('/profile/'+item.UUID)}  className="wof-user-photo" style={{backgroundImage:'url('+loadImage(item.UUID)+')'}}></div>
          <div className="wof-header">
              <div className="wof-header-name">{item.FirstName}</div>
              <div className="wof-header-team">{item.LastName}</div>
          </div> 
        </div>
        <div className="wof-user-photo" style={{backgroundImage:'url('+`${burnWhite}`+')'}}><span style={{color:'#f95d2d', fontSize:'14px', fontWeight: 'bold'}}>{index+1}</span></div>        
      </div>
      <div className="wof-points"><img src={flame} alt="burn icon"/>{item.v} </div>
    </div>      
    )
   // }
  })


  const ageChange=(n)=>{
    setAge(n)
    if (n==0){
      setWallOfFame(allResults.slice(0, 19))
    }

    if (n==1){
      setWallOfFame(allResults.filter(item=>item.age>=13 && item.age<=16))
    }

    if (n==2){
      setWallOfFame(allResults.filter(item=>item.age>=16 && item.age<=20))
    }

    if (n==3){
      setWallOfFame(allResults.filter(item=>item.age>20))
    }
  }

  return (
<>

<div className="container container-mobile" style={{backgroundImage:'url('+`${burnBg}`+')',paddingBottom: '40px'}}>
      <header>
          <div className="upper-section bg-white">
            <div className="d-flex align-items-center">

              <div className="upper-texts">
                <div className="text1-w"><span style={{color:'#f95d2d', fontWeight:'bold'}}>TOP 20</span><span style={{color: 'grey'}}></span></div>
                <div className="text2-g fw-bold">Wall of Fame</div>
              </div>
            </div>
            
            <div onClick={()=>navigate('/profile/'+userID)}  className="upper-photo" style={{backgroundImage:'url('+`${process.env.REACT_APP_API_SERVER}files/${userID}/1234`+')'}}><span style={{color:'white', fontSize:'18px', fontWeight: 'bold'}}>
              {/* 186 */}
              <h4 style={{color:'white',textShadow:'2px 2px 4px #000000'}}>{myScore}</h4>
              </span></div>
          </div>    
      </header>
 
        {isLoading &&
           <>
           <br/><br/><br/><br/>
           <div className="home-posts-item">
              <div className="alert alert-secondary" role="alert">
              Loading, please wait...
            </div>  
            </div> 
            </>              
            }

      {!isLoading &&
        <section className="section-margin">
            <div style={{padding:20}}>
            <select value={age} onChange={(e)=>ageChange(e.target.value)} className="form-select" aria-label="Default select example" >
              <option value="0">Top 20</option>
              <option value="1">13-16 years old</option>
              <option value="2">16-20 years old</option>
              <option value="3">20+ years old</option>
            </select>
            </div>

          {wallOfFameList}
        </section>
      }



    </div>


    
    
    
    </>
  )
}

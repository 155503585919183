import React,{useState,useEffect,useContext,createContext} from 'react'
import {Navigate,useNavigate} from 'react-router-dom'
import '../../css/style.css'
import {UserContext} from '../../context/UserContext'


import Footer from '../Components/Footer'
import axios from 'axios'

export default function MenuMyPosts() {
    const {isAuth,setIsAuth}=useContext(UserContext)
    const {apiToken,setApiToken} = useContext(UserContext)
    const {userID,setUserID }= useContext(UserContext)
    const {usrPhoto,setUsrPhoto}= useContext(UserContext)
    const {firstName,lastName}= useContext(UserContext)
    const [isLoading,setIsLoading]=useState(false)
    const [posts,setPosts]=useState([])
    const [challenges,setChallenges]=useState([])

    const navigate=useNavigate()

    useEffect(()=>{
        loadData()


        window.scrollTo({ top: 0, behavior: 'smooth' })
    },[])

    const loadData=()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'userposts/'+userID,{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem("token")
                }            
            })
          .then(res=>{
              setPosts(res.data.posts.filter(item=>item.Type==2))
              console.log(res.data.posts.filter(item=>item.Type==2))
              setChallenges(res.data.posts.filter(item=>item.Type==3))
             setIsLoading(false)
          }).catch(err=>{
            console.log(err)
              setIsLoading(false)
          })         
    }

    const deletePost=(uuid,type)=>{
      const msg=type==2 ? 'DELETE THIS POST! ARE YOU SURE?' : 'DELETE THIS CHALLENGE! ARE YOU SURE?'
      if (window.confirm(msg)){
        const obj={uuid,type}
        axios.post(process.env.REACT_APP_API_SERVER+'userposts/delete',obj,{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("token")
              }            
          })
        .then(res=>{
          if (res.data.err==0){
            loadData()
          }else{
            alert('An error has occured')
          }
        }).catch(err=>{
          console.log(err)
            setIsLoading(false)
        }) 
      }
    }

    const postsList=posts.map(item=>{
      const articlePhoto=`${process.env.REACT_APP_API_SERVER}postfiles/${item.UUID}/123`
      return(
        <>
        <div className="home2-post-image">
              {item.Photo?.length>0 &&
                <img  src={articlePhoto} alt="Post Image" className="dynamic-image"/>
              }

              {item.VideoID?.length>0 && 
               

                <video preload="false" playsInline type="video/mp4" id="videoPlayer" controls width="100%" height="100%" poster={process.env.REACT_APP_VIDEOSERVER_API+''+item.VideoID+'.jpg'}>
                    <source src={`${process.env.REACT_APP_VIDEO_BASE_URL}${item.VideoID}.smil/playlist.m3u8#t0.01`} type="video/mp4"/>
                </video>
                }
        </div>
        <br/>
        <p align="center">
        <button type="button" class="btn btn-danger" onClick={()=>deletePost(item.UUID,2)}>Delete Post</button>
        </p>
        <br/>
        <hr/>
        </>
      )
    })

    const challengesList=challenges.map(item=>{
      const articlePhoto=`${process.env.REACT_APP_API_SERVER}postfiles/${item.UUID}/123`
      return(
        <>
        <div className="home2-post-image">
              {item.Photo?.length>0 &&
                <img  src={articlePhoto} alt="Post Image" className="dynamic-image"/>
              }

              {item.VideoID?.length>0 && 
               

                <video preload="false" playsInline type="video/mp4" id="videoPlayer" controls width="100%" height="100%" poster={process.env.REACT_APP_VIDEOSERVER_API+''+item.VideoID+'.jpg'}>
                    <source src={`${process.env.REACT_APP_VIDEO_BASE_URL}${item.VideoID}.smil/playlist.m3u8#t0.01`} type="video/mp4"/>
                </video>
                }
        </div>
        <br/>
        <p align="center">
        <button type="button" class="btn btn-danger" onClick={()=>deletePost(item.UUID,3)}>Delete Challenge Video</button>
        </p>
        <br/>
        <hr/>
        </>
      )
    })

    const deleteAccount=()=>{
    //   axios.get(process.env.REACT_APP_API_SERVER+'userprofile/deleteaccount/'+userID,{
    //     headers: {
    //         'Authorization': 'Bearer '+sessionStorage.getItem("token")
    //         }            
    //     })
    //   .then(res=>{
    //     if (res.data.err==0){
    //      // alert('Success')
    //       localStorage.removeItem("Password")
    //       sessionStorage.setItem("isAuth",0)
    //       navigate('/signup')          
    //     }else{
    //       alert('An error has occured')
    //     }

    //   }).catch(err=>{
    //     alert('An error has occured')
    //   })        
    }



    const save=(e)=>{
        e.preventDefault()
        // const obj={Password:p1}
        // obj.id=userID

        // axios.post(process.env.REACT_APP_API_SERVER+'changepassword',obj,{
        //   headers: {
        //       'Authorization': 'Bearer '+sessionStorage.getItem("token")
        //       }            
        //   })        
        // .then(res=>{
    
        //   if (res.data.err==0){
            
        //     setProfileSaved(1)
        //     navigate('/menu')
        //   }else{
        //     setProfileSaved(2)
        //   }

        //   //setTimeout(setProfileSaved(0),2000)
    
        // }).catch(err=>{
        //   alert("An error has occured, please try again")
        // })         

    }

  return (
    <>
      <header>
      <div className="upper-section upper-dark">
        <div className="d-flex align-items-center">
            {/* <div className="upper-arrow">
                <button><img src="images/left-arrow.png"/></button>
              </div> */}
          <div className="upper-texts">
            <div className="text1-w">my posts & challenges</div>
            <div className="text2-g2">{firstName} {lastName}</div>
          </div>
        </div>
        <div onClick={()=>navigate('/profile/'+userID)}  className="upper-photo" style={{backgroundImage:'url('+usrPhoto+')'}}></div>
      </div>    
  </header>

  <section className="pb-4 section-margin">
  <div className="container container-mobile">
      <div className="div-wrap">
        
        {isLoading &&
          <div className="alert alert-warning" role="alert">
          Loading, please wait...
          </div>          
        }
        
        {!isLoading &&
            <>
            {posts.length>0 &&
              <>
              <div className="alert alert-dark" role="alert"><b>Posts</b></div>
              {postsList}
              <br/>
              </>
            }
            {challenges.length>0 &&
              <>
              <div className="alert alert-dark" role="alert"><b>Challenges</b></div>
              {challengesList}
              </>
            }
            </>



   
        }
      </div>
    </div>  





  </section>
  </>
  )
}

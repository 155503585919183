import React,{useState,useEffect,useContext,createContext,useRef} from 'react'
import { useParams,Navigate,useNavigate } from 'react-router-dom'
import axios from 'axios'
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  Avatar,
  TypingIndicator,
  Conversation,
  ConversationList,
  ConversationHeader
} from "@chatscope/chat-ui-kit-react";
import moment from 'moment'
import io from 'socket.io-client'
import {UserContext} from '../../context/UserContext'
import leftArrow from  '../../css/images/left-arrow.png'
import burnBg from '../../css/images/burn-bg.png'
import './chatStyle.css'

export default function ChatRoom() {
  const {isAuth,setIsAuth}=useContext(UserContext)
  const {apiToken,setApiToken} = useContext(UserContext)
  const {userID,setUserID }= useContext(UserContext)
  const {usrPhoto,setUsrPhoto}= useContext(UserContext)
  const {firstName,lastName}= useContext(UserContext)
  const [isLoading,setIsLoading]=useState(false)
  const {sportID,langID}= useContext(UserContext)
  const [sports,setSports]=useState([])
  const [countries,setCountries]=useState([])
  const [positions,setPositions]=useState([])
  const [usr,setUsr]=useState({})
  const [profileSaved,setProfileSaved]=useState(0)
  const [reactions,setReactions]=useState([])
  const [challenge,setChallenge]=useState({})
  const [challenges,setChallenges]=useState([])
  const [friendShip,setFriendShip]=useState([])
  const [stories,setStories]=useState([])
  const [photos,setPhotos]=useState([])
  const [numOfPosts,setNumOfPosts]=useState(0)
  const [room,setRoom]=useState({})
  const [messages,setMessages]=useState([])
  const [messagesCheck,setMessagesCheck]=useState([])
  const [otherUserPhoto,setOtherUserPhoto]=useState('')
  const {socket,setSocket}=useContext(UserContext)
  const [isTyping,setIsTyping]=useState(false)
  const [currentMessage,setCurrentmessage]=useState('')
  const {loadUnreadMessages}=useContext(UserContext)
  
 // const [socketID,setSocketID]=useState('')
  const navigate=useNavigate()
  const {roomUUID}=useParams()


  useEffect(()=>{
    loadRoom()
    updateReadStatus()
    loadUnreadMessages(userID)
  },[])

  useEffect(()=>{
    
    socket.on("receive-message",(messageData)=>{
      setMessages((list)=>[...list,messageData])
      updateReadStatus()
    })

    socket.on("user-is-typing",UserWhoTypesUUID=>{
      //console.log("he's typing")
      if (UserWhoTypesUUID!==userID){
        setIsTyping(true)
      }
      
    })

    socket.on("user-stopped-typing",UserWhoTypesUUID=>{
      //console.log("he stoped")
      if (UserWhoTypesUUID!==userID){
        setIsTyping(false)
      }
      
    })
    
    return () => {
      socket.off('receive-message');
    };

  },[socket])

  useEffect(()=>{
    if (currentMessage.length>0){
      socket.emit('me-typing',userID,roomUUID)
    }else{
      socket.emit('me-stopped-typing',userID,roomUUID)
    }
  },[currentMessage])


  const loadRoom=()=>{
    setIsLoading(true)
    axios.get(process.env.REACT_APP_API_SERVER+'messenger/rooms/'+roomUUID+'/'+userID,{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("token")
          }            
      })
    .then(res=>{
      setRoom(res.data)
      setOtherUserPhoto(loadImage(res.data.ChatWithUUID))
      loadMessages()
      setIsLoading(false)
    }).catch(err=>{
        setIsLoading(false)

    })      
  }

  const loadMessages=()=>{
    axios.get(process.env.REACT_APP_API_SERVER+'messenger/messages/'+roomUUID,{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("token")
          }            
      })
    .then(res=>{
      //console.log(res.data.messages)
      setMessages(res.data.messages)
    }).catch(err=>{
        setIsLoading(false)

    })      
  }

  const loadImage=(id)=>{
    const d=Date.now()
    return(`${process.env.REACT_APP_API_SERVER}files/${id}/${d}`)
  }



const messagesList=messages.map((item,index)=>{
  var sender=''
  var direction=''
  var avatar
  if (item.SenderUUID==userID){
    sender=firstName+ ' '+lastName
    direction='outgoing'
    avatar=usrPhoto
  }else{
    sender=room.ChatWithName
    direction='incoming'
    avatar=otherUserPhoto
  }
  const DTCreated=moment(item.DateCreated.split('T')[0]).format('DD/MM/YYYY')
  //+' '+item.DateCreated.split('T')[1].substr(0,5)

  return(

    <Message
      key={index}
      model={{
        message: item.Message,
        sentTime: item.DateCreated,
        sender,
        direction,
        position:'single'
      }} 
    >
      <Message.Header sender={sender} sentTime={DTCreated} />
      <Avatar
      style={{

        objectFit:'cover'
      }}
        name={sender}
        src={avatar}
        size="md"

      />
 
    </Message> 

  )
})


const sendMessage=async (msg)=>{


  let currentDate = new Date();

  // Get the components of the date and time
  let year = currentDate.getFullYear();
  let month = currentDate.getMonth() + 1; // Months are zero-indexed
  let day = currentDate.getDate();
  let hours = currentDate.getHours();
  let minutes = currentDate.getMinutes();
  let seconds = currentDate.getSeconds();
  
  // Format the components as a string
  let formattedDate = year + '-' + 
                      (month < 10 ? '0' + month : month) + '-' + 
                      (day < 10 ? '0' + day : day) + ' ' + 
                      (hours < 10 ? '0' + hours : hours) + ':' + 
                      (minutes < 10 ? '0' + minutes : minutes) + ':' + 
                      (seconds < 10 ? '0' + seconds : seconds);
  
  //alert('Current local date and time: ' + formattedDate);



  const obj={
    RoomUUID:roomUUID,
    SenderUUID:userID,
    ReceiverUUID:room.ChatWithUUID,
    Message:msg
  }
  setCurrentmessage('')
  await socket.emit("send-message",obj)

  axios.post(process.env.REACT_APP_API_SERVER+'messenger/messages/',obj,{
    headers: {
        'Authorization': 'Bearer '+sessionStorage.getItem("token")
        }            
    })
  .then(res=>{
    //loadMessages()
    //setIsTyping(false)
    setIsLoading(false)
  }).catch(err=>{
      setIsLoading(false)

  })  
}

const updateReadStatus=()=>{
  axios.get(process.env.REACT_APP_API_SERVER+'messenger/messages/update/'+roomUUID+'/'+userID,{
    headers: {
        'Authorization': 'Bearer '+sessionStorage.getItem("token")
        }            
    })
  .then(res=>{
    //console.log(res.data.messages)
    //setMessages(res.data.messages)
  }).catch(err=>{
    console.log(err)
  })  
}

  const meTyping=(msg)=>{
    setCurrentmessage(msg)
  }

  return (
    <>
    

   


      <header>
          <div className="upper-section upper-dark">
          <div className="d-flex align-items-center">
            <div className="upper-arrow">
              <button onClick={()=>navigate(-1)}><img src={leftArrow}/></button>
            </div>
            <div className="upper-texts">
              <div className="text1-w">Chat</div>
              <div className="text2-g2">with {room.ChatWithName}</div>
            </div>
          </div>

        </div>    
      </header>

{/* <header>
          <div className="profile3-upper-section upper-dark" style={{height: '70px'}}>
            <div className="d-flex align-items-center">
              <div className="upper-arrow">
              <button onClick={()=>navigate(-1)}><img src={leftArrow}/></button>
              </div>
              <div className="upper-texts">
              <div className="text1-w">Chat</div>
              <div className="text2-g2">with {room.ChatWithName}</div>
              </div>
            </div>
            
          </div>    
      </header> */}


        {!isLoading &&
        <div style={{height: "100vh",backgroundColor:'white',top:'500px',paddingTop:'100px'}}>
          
          <MainContainer style={{height:'100%',width:'100%',borderColor:'white'}}>
            
            <ChatContainer>

              <MessageList typingIndicator={isTyping ? <TypingIndicator/> : null}>
                  {messagesList}
              </MessageList>

              <MessageInput value={currentMessage} onChange={(e)=>meTyping(e)}  placeholder="Type message here" attachButton={false} onSend={(msg)=>sendMessage(msg)}/>
            </ChatContainer>
            
          </MainContainer>
          
        </div>
        }

          {isLoading &&
           <>
           <br/><br/><br/><br/>
           <div className="home-posts-item">
              <div className="alert alert-secondary" role="alert">
              Loading, please wait...
            </div>  
            </div> 
            </>              
            }        









    </>
  )
}

import React,{useState,useEffect,useContext,createContext,useRef} from 'react'
import { useParams,Navigate,useNavigate } from 'react-router-dom'
import '../../../css/style.css'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {UserContext} from '../../../context/UserContext'
import PullToRefresh from 'react-simple-pull-to-refresh'
import axios from 'axios'
import otthLogo from '../../../css/images/logo-b-orange.jpg'
import leftarrow from '../../../css/images/left-arrow.png'
import loading from '../../../css/images/loading.gif'
import loadingb from '../../../css/images/loadingb.gif'


//import Header from './Header';
import WallPostItem from './WallPostItem';

export default function Home2Profile() {
  const {isAuth,setIsAuth}=useContext(UserContext)
  const {apiToken,setApiToken} = useContext(UserContext)
  const {userID,setUserID }= useContext(UserContext)
  const {usrPhoto,setUsrPhoto}= useContext(UserContext)
  const {firstName,setFirstName,lastName,setLastName}= useContext(UserContext)
  const {itemToView,setItemToView}= useContext(UserContext)
  const {sportID,setSportID,langID,setLangID}= useContext(UserContext)
  const {profileIsPublic,setProfileIsPublic}= useContext(UserContext)
  const [articles,setArticles]=useState([])
  const [reactions,setReactions]=useState([])
  const [postreactions,setPostReactions]=useState([])
  const [articlesFlt,setArticlesFlt]=useState([])
  const [isLoading,setIsLoading]=useState(false)
  const [scrollY, setScrollY] = useState(0);
  const [prevScrollY, setPrevScrollY] = useState(0);
  const [image, setImage] = useState(null);
  const [stories,setStories]=useState([])
  const {usersWhoBlockedMe,setUsersWhoBlockedMe}=useContext(UserContext)
  const {loadUsersWhoBlockedMe}=useContext(UserContext)
  const {newChallenge,setNewChallenge}=useContext(UserContext)
  const [friends,setFriends]=useState([])
  const [searchText,setSearchText]=useState('')
  const { loadUnreadNotifications}=useContext(UserContext)
  const [page,setPage]=useState(1)
  const [usr,setUsr]=useState({})
  const navigate=useNavigate()
  const vidEl=useRef()
  const s=useRef()
  const {uid}=useParams()
  const {itemUUID}=useParams()


  let lastScrollTop = 0;
  let lastUpScrollTop = 0;
  let offsetToShow = 20; // Το σημειο σε Pixels που θα εμφανιστει το header ξανα 
  let scrollUpThreshold = 20; // Το όριο σε pixel για κύλιση προς τα πάνω

  let ticking = false;

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 4
    }
  };

  useEffect(()=>{
    
    //loadData()
    refresh().then(r=>{
      loadUsr()
      //document.getElementById(itemUUID).scrollIntoView()
      setIsLoading(false)
    }).catch(err=>{
      setIsLoading(false)
    })

    const images = document.querySelectorAll('.dynamic-image');
    images.forEach(img => {
        img.onload = function() {
            if (this.naturalWidth >= 1080) {
                this.classList.add('wide');
                console.log(`Class 'wide' added to image: ${this.src}`);
            } else {
                this.classList.add('narrow');
                console.log(`Class 'narrow' added to image: ${this.src}`);
            }
            adjustParentHeight(this);
        };
        img.onerror = function() {
            console.error(`Failed to load image: ${this.src}`);
        };
    });




    function adjustParentHeight(img) {
      const parent = img.closest('.home2-post-image');
      parent.style.height = img.height + 'px';
  }
  






    if (document.querySelector('.challenge-add-upper-section.upper-dark') && document.querySelector('footer')){
      window.addEventListener('scroll', handleScroll);
      //console.log(1)
    }

    window.addEventListener('fullscreenchange', handleFullScreenChange);
  
    return () => {
      window.removeEventListener('scroll',handleScroll);
       window.removeEventListener('fullscreenchange', handleFullScreenChange);
     };
  },[])



  useEffect(()=>{
    
    if (articles.length>0 && document.getElementById(itemUUID)){
      //console.log(document.getElementById(itemUUID))
      setTimeout(()=>document.getElementById(itemUUID).scrollIntoView(),500)
      
    }
    
  },[articles])

  function isElementInViewport(el) {
    var rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }
  

  const loadUsr=()=>{
    setIsLoading(true)
    axios.get(process.env.REACT_APP_API_SERVER+'userprofile/'+uid,{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("token")
          }            
      })
    .then(res=>{

       setUsr(res.data.results)

       setIsLoading(false)
    }).catch(err=>{
        setIsLoading(false)

    })          
}


  function isVideoInView() {
    var videos = document.getElementsByTagName('video');
    for (var i = 0; i < videos.length; i++) {
      if (isElementInViewport(videos[i])) {
        
  
        for (var k = 0; k < videos.length; k++) {
          videos[k].pause();
        }
  
        return true;
      }
    }
    return false;
  }

  //-------------------FULL SCREEN CHANGE-------------------------------------------

  const handleFullScreenChange=()=>{
    window.scrollTo({
      top: sessionStorage.getItem('prevScrollY'),//prevScrollY
    });  


    if (document.fullscreenElement){
      window.scrollTo({
        top: sessionStorage.getItem('prevScrollY')+1,//prevScrollY
      });  
    }else{
      window.scrollTo({
        top: sessionStorage.getItem('prevScrollY')+1,//prevScrollY
      });  
    }
  }
//-----------------------SCROLL FUNCTIONS---------------------------------------------

const handleScroll=()=>{
  //alert(ticking)
  if (!ticking) {
    window.requestAnimationFrame(onScroll);
    ticking = true;
  }
}

const onScroll=()=> {
  //try{
    const header = document.querySelector('.challenge-add-upper-section.upper-dark');
    const footer = document.querySelector('footer');

    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;


    if (scrollTop > lastScrollTop) {
        // Scrolling down
        if (scrollTop > offsetToShow) {
            header.style.transform = 'translateY(-100%)';
            footer.style.transform = 'translateY(100%)';
        }
    } else {
        // Scrolling up
        if (scrollTop < offsetToShow || (lastUpScrollTop - scrollTop > scrollUpThreshold)) {
            header.style.transform = 'translateY(0)';
            footer.style.transform = 'translateY(0)';
        }
        lastUpScrollTop = scrollTop; // Update lastUpScrollTop μονο οταν γινεται scrolling up
    }
    lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
    ticking = false;
    isVideoInView()
  // }catch(err){

  // }

}


const handleScroll2=()=>{
  const header = document.querySelector('.challenge-add-upper-section.upper-dark');
  const footer = document.querySelector('footer');
  let lastScrollTop = 0;
  const offsetToShow = 120; // The offset in pixels from the top where the header should appear again
  let ticking = false;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  sessionStorage.setItem('prevScrollY',sessionStorage.getItem('scrollY'))
  sessionStorage.setItem('scrollY',window.scrollY)


  // if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 500) {
  //   const newPage=page+1
  //   setPage(newPage)
  //   loadData(page)
  // }

  if (scrollTop > lastScrollTop) {
    // Scrolling down
    if (scrollTop > offsetToShow) {
      //console.log('down')
      header.style.transform = 'translateY(-100%)';
      footer.style.transform = 'translateY(100%)';
    }
  } else {
    // Scrolling up
    if (scrollTop < offsetToShow) {
      header.style.transform = 'translateY(0)';
      footer.style.transform = 'translateY(0)';
    }
  }
  lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
  ticking = false;
  isVideoInView()
 
}


  const refresh=()=>{
    return new Promise((resolve, reject) => {
      setIsLoading(true)
      axios.get(process.env.REACT_APP_API_SERVER+'wallarticlesnewprofile/'+sportID+'/'+langID+'/'+uid+'/'+profileIsPublic+'/1',{
        headers: {
            'Authorization': 'Bearer '+sessionStorage.getItem("token")
            }            
        })
      .then(res=>{
        //console.log(res.data)
        //setStories(res.data.posts.filter(post=>post.IsStory==1))
        setArticles(res.data.posts.filter(post=>post.IsStory==0))
        setArticlesFlt(res.data.posts)
        // setArticles(res.data.posts.filter(post=>post.IsStory==0))
        // setArticlesFlt(res.data.posts.filter(post=>post.IsStory==0))
        setIsLoading(false)
        resolve(1)
      }).catch(err=>{
          setIsLoading(false)
          reject(0)
      }) 
    })
  }



  const articlesList=articles.map((item,index)=>{
    var articlePhoto=''
    var author=''
    var authorImg=''
    if (item.PostedBy==0){
      articlePhoto=`${process.env.REACT_APP_API_SERVER}admin/files/${item.UUID}/123`
      author='123High'
      authorImg=otthLogo
    }else{
      author=item.FirstName+ ' '+item.LastName
      authorImg=`${process.env.REACT_APP_API_SERVER}files/${item.UsrUUID}/1234`
      articlePhoto=`${process.env.REACT_APP_API_SERVER}postfiles/${item.UUID}/123`

    }

    return(
      <div id={`${item.UUID}`} key={`wpidiv${index}`}>

      <WallPostItem  key={`wpi${index}`} index={index} item={item} articlePhoto={articlePhoto} author={author} authorImg={authorImg}/>

      </div>
    )

  })




  return (

      <>
              <header>
                <div className="challenge-add-upper-section upper-dark">
                  <div className="d-flex align-items-center">
                    <div className="upper-arrow">
                      <button onClick={()=>navigate('/profile/'+uid)}><img src={leftarrow}/></button>
                    </div>
                    <div className="text1-w">{usr.FirstName} {usr.LastName} <span style={{color: '#f95d2d'}}></span> - Posts</div>
                  </div>
                </div>                
        </header>

        <section>



        {isLoading &&
           <>
           <br/><br/>
           <div className="home-posts-item">
              <p align="center">
                <img src={sportID==2 ? loadingb : loading} width="100"/>
              </p>
            </div> 
            </>              
        }

        {!isLoading ? articlesList : ''}




        </section>

      </>
 
    
  )
}

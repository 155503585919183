import React,{useState,useEffect,useContext,createContext} from 'react'
import {Navigate,useNavigate} from 'react-router-dom'
import '../../css/style.css'
import {UserContext} from '../../context/UserContext'
// import Logo from '../../css/images/logo-high3.png'
// //import bgImage from '../../css/images/Signup3.jpg'
// import bgImage from '../../css/images/MainBg.png'

import Footer from '../Components/Footer'
import axios from 'axios'

export default function Menu() {
    const {isAuth,setIsAuth}=useContext(UserContext)
    const {apiToken,setApiToken} = useContext(UserContext)
    const {userID,setUserID }= useContext(UserContext)
    const {usrPhoto,setUsrPhoto}= useContext(UserContext)
    const {firstName,lastName}= useContext(UserContext)
    const {profileIsPublic,setProfileIsPublic}= useContext(UserContext)
    const [blockeUsersNum,setBlockeUsersNum]=useState(0)
    const { loadUnreadNotifications}=useContext(UserContext)
    const navigate=useNavigate()

    useEffect(()=>{
        loadBlockedUsers()
        loadUnreadNotifications(userID)
        window.scrollTo({ top: 0, behavior: 'smooth' })
    },[])



const loadBlockedUsers=()=>{

    axios.get(process.env.REACT_APP_API_SERVER+'block/'+userID,{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("token")
          }            
      })
    .then(res=>{

        setBlockeUsersNum(res.data.friends.length)

    })
}
    
    const changePrivacy=(p)=>{
       // setProfileIsPublic(p)

        const obj={ProfileIsPublic:p}
        obj.id=userID

        axios.post(process.env.REACT_APP_API_SERVER+'changeprivacy',obj,{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("token")
              }            
          })        
        .then(res=>{
    
          if (res.data.err==0){
            
            setProfileIsPublic(profileIsPublic==1 ? 0 : 1)
  
          }

          //setTimeout(setProfileSaved(0),2000)
    
        }).catch(err=>{
          alert("An error has occured, please try again")
        })   
       
    }

    const disconnect=()=>{

        localStorage.removeItem("Password")
        sessionStorage.setItem("isAuth",0)
        navigate('/login')
    }

  return (
    <>
      <header>
      <div className="upper-section upper-dark">
        <div className="d-flex align-items-center">
            {/* <div className="upper-arrow">
                <button><img src="images/left-arrow.png"/></button>
              </div> */}
          <div className="upper-texts">
            <div className="text1-w">app</div>
            <div className="text2-g2">Settings</div>
          </div>
        </div>
        <div onClick={()=>navigate('/profile/'+userID)}  className="upper-photo" style={{backgroundImage:'url('+usrPhoto+')'}}></div>
      </div>    
  </header>

  <section className="pb-4 section-margin">
      <div className="settings-item" onClick={()=>navigate('/menume')}>
          <div className="setting-title">
              <div className="setting-icon"><i className="bi bi-pencil-fill"></i></div>    
              <div className="setting-text">Me</div>    
          </div>
          <div className="setting-value">{firstName} {lastName}</div>      
      </div>
      <div className="settings-item" onClick={()=>navigate('/menumyposts')}>
          <div className="setting-title">
              <div className="setting-icon"><i className="bi bi-gear-fill"></i></div>    
              <div className="setting-text">My Posts</div>    
          </div>
          <div className="setting-value"></div>      
      </div>
      <div className="settings-item" onClick={()=>navigate('/menuprofilephoto')}>
          <div className="setting-title">
              <div className="setting-icon"><i className="bi bi-camera-fill"></i></div>    
              <div className="setting-text">Profile Photo</div>    
          </div>
          <div className="setting-value"></div>      
      </div>

      {/* <div className="settings-item">
          <div className="setting-title">
              <div className="setting-icon"><i className="bi bi-bell-fill"></i></div>    
              <div className="setting-text">Notifications</div>    
          </div>
          <div className="setting-value">All</div>      
      </div> */}
      {/* <div className="settings-item">
          <div className="setting-title">
              <div className="setting-icon"><i className="bi bi-gear-fill"></i></div>    
              <div className="setting-text">General</div>    
          </div>
          <div className="setting-value">Compress Photos</div>      
      </div> */}
      <div className="settings-item" onClick={()=>navigate('/account')} >
          <div className="setting-title">
              <div className="setting-icon"><i className="bi bi-person-fill"></i></div>    
              <div className="setting-text">Account</div>    
          </div>
          <div className="setting-value"></div>      
      </div>
      <div className="settings-item" onClick={()=>{changePrivacy(profileIsPublic==1 ? 0 : 1)}} >
          <div className="setting-title">
              <div className="setting-icon">< i className="bi bi-briefcase-fill"></i></div>    
              <div className="setting-text" >Privacy</div>    
          </div>
          <div className="setting-value" style={{color:profileIsPublic==1 ? 'green' : 'red',fontSize:14}}>{profileIsPublic==1 ? 'Public' : 'Only me and my friends'}</div>      
      </div>
      <div className="settings-item" onClick={()=>blockeUsersNum>0 ? navigate('/blocks') : null} >
          <div className="setting-title">
              <div className="setting-icon"><i className="bi bi-ban"></i></div>    
              <div className="setting-text">Block</div>    
          </div>
          <div className="setting-value">{blockeUsersNum} Users</div>      
      </div>

      <div className="settings-item" onClick={()=>disconnect()} >
          <div className="setting-title">
              <div className="setting-icon">< i className="bi bi-person-fill-x"></i></div>    
              <div className="setting-text" >Logout</div>    
          </div>
     
      </div>

      {/* <div className="settings-item" onClick={()=>navigate('/home2')} >
          <div className="setting-title">
              <div className="setting-icon"><i className="bi bi-person-fill"></i></div>    
              <div className="setting-text">Home2</div>    
          </div>
          <div className="setting-value"></div>      
      </div> */}

      {/* <div className="settings-item">
          <div className="setting-title">
              <div className="setting-icon"><i className="bi bi-question-circle-fill"></i></div>    
              <div className="setting-text">Help</div>    
          </div>
          <div className="setting-value">Questions?</div>      
      </div> */}
  </section>
  </>
  )
}

import React,{useRef,useState,useEffect,useContext} from 'react'
import { useParams,Navigate,useNavigate } from 'react-router-dom'
import timer3 from  '../../css/images/timer3.png'
import leftArrow from  '../../css/images/left-arrow.png'
import playOrange from  '../../css/images/play-orange.png'
import flame from  '../../css/images/flame.png'
import thunder from  '../../css/images/thunder.png'
import achievement from  '../../css/images/achievement.png'
import man2 from '../../css/images/man2.jpg'
import man from '../../css/images/man.jpg'
import man3 from '../../css/images/man3.jpg'
import basket1 from '../../css/images/basket1.jpg'
import basket2 from '../../css/images/basket2.jpg'
import basket3 from '../../css/images/basket3.jpg'
import moment from 'moment'
import {UserContext} from '../../context/UserContext'
import axios from 'axios'


export default function ChallengePost() {
    const {isAuth,setIsAuth}=useContext(UserContext)
    const {apiToken,setApiToken} = useContext(UserContext)
    const {userID,setUserID }= useContext(UserContext)
    const {profileIsPublic,setProfileIsPublic}= useContext(UserContext)
    const {usrPhoto,setUsrPhoto}= useContext(UserContext)
    const {firstName,setFirstName,lastName,setLastName}= useContext(UserContext)
    const {itemToView,setItemToView}= useContext(UserContext)
    const {sportID,setSportID,langID,setLangID}= useContext(UserContext)
    const [articles,setArticles]=useState([])
    const [articlesFlt,setArticlesFlt]=useState([])
    const [isLoading,setIsLoading]=useState(false)
    const [hasMedia,sethasMedia]=useState(false)
    const [file,setFile]=useState(null)
    const [fileType,setFileType]=useState('')
    const [fileSource, setFileSource] = useState(null)
    const [postText,setPostText]=useState('')
    const [postVideo,setPostVideo]=useState('')
    const [postPhoto,setPostPhoto]=useState('')
    const [upLoading, setUpLoading]=useState(false)
    const [uploadPercentage, setUploadPercentage]=useState(0)
    const [videoURI,setVideoURI]=useState(null)
    const [challengeNotes,setChallengeNotes]=useState('')
    const [reactions,setReactions]=useState([])
    const [challengeTaken,setChallengeTaken]=useState(1)
    const [usersWhoBlockedMe,setUsersWhoBlockedMe]=useState([])
    const [friends,setFriends]=useState([])
    const [totalRecations3,setTotalReactions3]=useState(0)
    const [totalRecations2,setTotalReactions2]=useState(0)
    const [totalRecations1,setTotalReactions1]=useState(0)

    const fileInputRef = useRef(null)
    const vidEl=useRef()
  
  
    const [challenge,setChallenge]=useState({})
    const [challenges,setChallenges]=useState([])
    const {id}=useParams()
    const {langid}=useParams()
    const navigate=useNavigate()

    useEffect(()=>{
        loadData()
    },[])

    const loadData=()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'challenge/'+id+'/'+langid,{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("token")
              }            
          })
        .then(res=>{
          const present=moment(Date.now())
          const startChallenge=moment(res.data[0].DateTo+' 23:59:59')

          const dif=startChallenge.diff(present, 'hours')
          res.data[0].dif=dif

           setChallenge(res.data[0])
           loadChallenges(res.data[0].UUID)
        }).catch(err=>{
            setIsLoading(false)
        })          
    }

    const loadMyFriends=async ()=>{
      return new Promise((resolve,reject)=>{
        axios.get(process.env.REACT_APP_API_SERVER+'friends/userfriendsidonly/'+userID,{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("token")
              }            
          })
        .then(res=>{
    
          setFriends(res.data.friends)
          resolve(res.data.friends)
        })
      })
      
    }


    const loadChallenges=(uuid)=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'challengelistbyid/challengeid/'+uuid+'/'+userID,{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("token")
              }            
          })
        .then(res=>{
          loadMyFriends().then(myFriends=>{

              setChallengeTaken(res.data.posts.filter(item=>item.UserID==userID).length)
              //var articlesResults=[]
              //console.log(res.data.posts)
              var articlesResults=res.data.posts
              // res.data.posts.forEach(item=>{
              //   if (item.ProfileIsPublic==0){
              //     if (myFriends.filter(f=>f.uid==item.UserID).length>0 || item.UserID==userID){
              //       articlesResults.push(item)
              //     }
              //   }else{
              //     if (profileIsPublic==1){
              //       articlesResults.push(item)
              //     }else{
              //       if (myFriends.filter(f=>f.uid==item.UsrUUID).length>0 || item.UsrUUID==userID){
              //         articlesResults.push(item)
              //       }                  
              //     }
              //   }
              // })



              setChallenges(articlesResults.filter(item=>item.LangID==langID))
              //setChallenges(articlesResults)
              setReactions(res.data.reactions)
              calculateTotalReactions(res.data.reactions)
              //console.log(res.data.reactions)
              //console.log(res.data.posts)
              setIsLoading(false)
          })
        }).catch(err=>{
            setIsLoading(false)
        })          
    }


    const calculateTotalReactions=(rct)=>{
      var tr=0
      if (rct.filter(xitem=>xitem.RankType==3).length>0){
          rct.filter(xitem=>xitem.RankType==3).forEach(xitem=>{
          tr=tr+parseInt(xitem.v)
        })
        setTotalReactions3(tr)  
      }
      var tr=0
      if (rct.filter(xitem=>xitem.RankType==2).length>0){
            rct.filter(xitem=>xitem.RankType==2).forEach(xitem=>{
            tr=tr+parseInt(xitem.v)
          })
          setTotalReactions2(tr) 
      }
      var tr=0
      if (rct.filter(xitem=>xitem.RankType==1).length>0){
          rct.filter(xitem=>xitem.RankType==1).forEach(xitem=>{
          tr=tr+parseInt(xitem.v)
          })
          
          setTotalReactions1(tr) 
      }

  }




    const challengesList=challenges.map((item,index)=>{
        //console.log(item)
        const d=Date.now()
        const xusrPhoto=process.env.REACT_APP_API_SERVER+'files/'+item.UserID+'/'+d
        const challengePhoto=process.env.REACT_APP_VIDEOSERVER_API+''+item.FileID+'.jpg'
       // console.log(xusrPhoto)

              return(
                //<div className="challenge-selected-posts-item" key={index} onClick={()=>navigate('/challengeprofilestory/'+id+'/'+langid+'/'+item.UserID)}>
                <div className="challenge-selected-posts-item" key={index} onClick={()=>viewItem(item)}>
                <div className="challenge-selected-post-image" style={{backgroundImage: 'url('+challengePhoto+')'}}>
    
                    <div className="challenge-selected-user-photo2 ms-3" style={{backgroundImage: 'url('+xusrPhoto+')'}}></div>
                    <div className="profile-post-points">
                        <div style={{fontSize:'10px'}}>
                            
                            <img src={flame} alt="flame icon"/>
                            {/* {totalRecations3} */}
                            
                            {reactions.filter(xitem=>xitem.RankType==3 && xitem.ChUserUUID==item.UserID).length}
                        </div>
                        
                        <div style={{fontSize:'10px'}}>
                            <img src={thunder} alt="thunder icon"/>
                            {/* {totalRecations2} */}
                            {reactions.filter(xitem=>xitem.RankType==2 && xitem.ChUserUUID==item.UserID).length}
                        </div>
                        
                        <div style={{fontSize:'10px'}}><img src={achievement} alt="achievement icon"/>
                        {/* {totalRecations1} */}
                        {reactions.filter(xitem=>xitem.RankType==1 && xitem.ChUserUUID==item.UserID).length}
                        
                        </div>
                    </div>
                </div>
                </div>         
            )


    })

    const viewItem=(item)=>{
      const itemObj={
        Type:'3',
        ItemUUID:item.UUID,
        ItemID:item.ChallengeID,
        VideoID:item.FileID,
        Photo:'',
        UsrUUID:item.UserID,
        UsrName:item.FirstName + " " + item.LastName,
        Descr:item.Descr,
        Title:item.Title,
        Reactions:reactions.filter(r=>r.ChUserUUID==item.UserID),
        Page:'/challengepost/'+item.UUID+'/'+langID
      }
  
      setItemToView(itemObj)
      navigate('/content')
    }

  return (
    <>


<div className="container container-mobile bg-grey">
      <header>
          <div className="upper-section upper-dark">
            <div className="d-flex align-items-center">
              <div className="upper-arrow">
              <button onClick={()=>navigate('/challenges')}><img src={leftArrow}/></button>
              </div>
              <div className="upper-texts">
                <div className="text1-w">Time</div>
                <div className="text2-g2">Remaining</div>
              </div>
            </div>
            <div className="d-flex align-items-center gap-2">
                <div className="challenge-time">{challenge.dif}h</div>
              <div className="upper-photo" style={{backgroundImage: 'url('+timer3+')'}}></div>  
            </div>
            
          </div>    
      </header>


            <div className="modal fade" id="Modal2" tabindex="-1" aria-labelledby="Modal2Label" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                  <div className="modal-content modal-specs">
                  <div className="modal-header">
                      <h1 className="modal-title fs-5" id="Modal2Label">{challenge.Title}</h1>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                      <div className="text-center">
                      <div className="fw-bold"></div>
                      <div className="modal-text height400">
                        <div
                        dangerouslySetInnerHTML={{ __html: challenge.Descr?.replace(/\n/g, '<br>') }}
                        >
                        </div>

                        </div> 
                      </div>
                      
                  </div>
                  <div className="modal-footer">
                      <div className="row w-100 m-0">
                      <div className="col p-0"><button type="button" className="w-100 fw-bold" data-bs-dismiss="modal">Close</button></div>
                      {/* <div className="col p-0"><button type="button" className="w-100 fw-bold" style="color: #f95d2d; border-left: 1px solid #f95d2d;">OK</button></div> */}
                      </div>    
                  </div>
                  </div>
              </div>
            </div>


            <section className="section-margin pt-0">
            <div className="challenge-image" style={{backgroundImage:'url('+`${process.env.REACT_APP_API_SERVER}/admin/files/${challenge.UUID}/123`+')'}}>
                <div className="active-members">Active Members: <span style={{color:'white', fontWeight:'bold', paddingLeft: '3px'}}> {challenge.ActiveMembers}</span></div>
                <div className="need-help">
                    Need Help?
                    <div className="help-buttons">
                        <button data-bs-toggle="modal" data-bs-target="#Modal2"><i className="bi bi-info-circle-fill" style={{color:'#f95d2d', fontSize: '20px'}}></i></button>

                        {challenge.VideoID?.length>0 &&
                        <button onClick={()=>navigate('/challengeexplainervideo/'+id+'/'+langid)}><img src={playOrange} alt="play icon"/></button>
                        // <button onClick={()=>viewItem(challenge)}><img src={playOrange} alt="play icon"/></button>
                        }
                    </div>
                </div>
            </div>

            {challengeTaken==0 &&
            <div className="challenge-selected-post-box" onClick={()=>navigate('/challengepostvideo/'+id+'/'+langid)}>
                {/* <div className="challenge-selected-user-photo" style={{backgroundImage:'url('+usrPhoto+')',width:'35px',height:'35px'}}></div> */}
                <div className="w-100">
                  <form>
                      <div className="challenge-selected-post" >
                          <div className="challenge-selected-box"  >
                            <input type="text" placeholder="Post your video" disabled ></input>  
                            <div className="challenge-selected-box-buttons">
                              <button onClick={()=>navigate('/challengepostvideo/'+id+'/'+langid)}  style={{backgroundColor: 'black', border: '1px solid black'}}><i className="bi bi-plus" style={{color: '#fff'}}></i></button>
                            </div>
                          </div>    
                      </div>
                  </form>          
                </div>
            </div>
            }
            {challengeTaken>0 &&
                <><br/><br/></>

            }
            <div className="challenge-selected-all-posts">
            {challengesList}
                  
            </div>  
        </section>



    </div>



    
    
    </>
  )
}
